<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<mask
			id="mask0_1273_2018"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_1273_2018)">
			<path
				d="M9.55001 18.0001L3.85001 12.3001L5.27501 10.8751L9.55001 15.1501L18.725 5.9751L20.15 7.4001L9.55001 18.0001Z"
				fill="currentColor"
			/>
		</g>
	</svg>
</template>
